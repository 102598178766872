<script>
import { isNil, clone } from "lodash";
import ValidatableMixin from "../validation/ValidatableMixin.vue";

export default {
  name: "bo-gen-entity-mixin",
  mixins: [ValidatableMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    return {
      entityName: undefined,
      includes: undefined,
      isDeleteDialogOpen: false
    };
  },
  computed: {
    storedEntity() {
      return clone(
        this.$store.getters["entities/getEntity"]({
          name: this.entityName,
          id: this.id
        })
      );
    },
    isCreate() {
      return isNil(this.id);
    },
    isInitialized() {
      return this.$store.getters["entities/isInitialized"]({
        name: this.entityName,
        id: this.id
      });
    },
    isLoading() {
      return this.$store.getters["entities/isLoading"]({
        name: this.entityName,
        id: this.id
      });
    },
    isLoaded() {
      return this.$store.getters["entities/isLoaded"]({
        name: this.entityName,
        id: this.id
      });
    },
    isUpdating() {
      return this.$store.getters["entities/isUpdating"]({
        name: this.entityName,
        id: this.id
      });
    }
  },
  watch: {
    id(newValue, oldValue) {
      if (newValue !== oldValue && !isNaN(newValue)) {
        this.init();
      }
    }
  },
  created: function() {
    this.init();
  },
  methods: {
    init() {
      if (!this.isCreate && !this.isInitialized) {
        const entityUrlFragment = `${this.entityName
          .charAt(0)
          .toUpperCase()}${this.entityName.slice(1)}`;
        this.$store.dispatch(`entities/fetch${entityUrlFragment}`, {
          id: this.id,
          includes: this.includes
        });
      }
    },
    transformEntity() {
      return clone(
        this.$store.getters["entities/getEntity"]({
          name: this.entityName,
          id: this.id
        })
      );
    },
    deleteEntity() {
      if (!isNil(this.id)) {
        const entityUrlFragment = `${this.entityName
          .charAt(0)
          .toUpperCase()}${this.entityName.slice(1)}`;
        this.$store.dispatch(`entities/delete${entityUrlFragment}`, {
          id: this.id,
          cb: this.redirectToOverview
        });
      }
    }
  }
};
</script>
