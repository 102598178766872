<script>
export default {
  name: "entity-overview-mixin",
  props: {
    create: Boolean,
    details: Boolean,
    wideDetails: Boolean,
    focused: Boolean
  },
  data() {
    return {
      entityName: undefined,
      includes: undefined
    };
  },
  computed: {
    dialog() {
      return this.create || this.details;
    }
  }
};
</script>
