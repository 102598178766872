<script>
import { isNil, isFunction } from "lodash";

export default {
  name: "validatable-mixin",
  inject: {
    $validator: "$validator"
  },
  methods: {
    onValidate: function() {
      this.errors.clear();
      this.$validator.validateAll().then(isEverythingValid => {
        if (isEverythingValid) {
          if (
            !isNil(this.onValidateSuccess) &&
            isFunction(this.onValidateSuccess)
          ) {
            this.onValidateSuccess();
          }
        } else {
          if (
            !isNil(this.onValidateFailure) &&
            isFunction(this.onValidateFailure)
          ) {
            this.onValidateFailure();
          }
          this.focusOnFirstError();
        }
      });
    },
    focusOnFirstError: function() {
      if (this.errors.items.length > 0) {
        // eslint-disable-next-line
        let fieldName = this.errors.items[0].field.replace("[]", "\\\[\\\]");
        document
          .querySelector(
            "input[name=" + fieldName + "], textarea[name=" + fieldName + "]"
          )
          .focus();
      }
    }
  }
};
</script>
